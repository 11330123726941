import React, { useContext, useEffect, useState } from "react";
import "./Menu.css";

import { Link } from "react-router-dom";

import { Grid, UserCircle } from "../_common/Icons";

import { AuthContext } from "../../App";

export default function PrivateMenu() {
  const { signOut, getIsSignout } = useContext(AuthContext);

  return (
    <div className="menu-container">
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "left",
          marginLeft: 10,
        }}
      >
        {/*<img src="./ProflowIcon.png" className="App-logo2" alt="logo" />*/}
        <Link to="/dashboard">
          <Grid size={35}></Grid>
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="./Tangible_Logo.png" className="App-logo2" alt="logo" />
          <h1 style={{ marginLeft: 10, fontWeight: 300 }}>Tangible</h1>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "right",
          marginRight: 10,
        }}
      >
        <Link to="/account">
          <UserCircle size={35}></UserCircle>
        </Link>
      </div>
    </div>
  );
}
