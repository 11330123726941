import React from "react";
import PrivateMenu from "../../components/Menu/PrivateMenu";

export default function Dashboard(props) {
  return (
    <div className="App">
      <PrivateMenu />
      <header className="App-section">
        <h1 style={{ fontWeight: 700 }}>Dashboard</h1>
      </header>
    </div>
  );
}
