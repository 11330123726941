import React, { useContext } from "react";
import PrivateMenu from "../../components/Menu/PrivateMenu";

import { AuthContext } from "../../App";

import Button from "../../components/Button";

export default function Account({ publicMenu }) {
  const { signOut, getIsSignout } = useContext(AuthContext);

  return (
    <div className="App">
      <PrivateMenu />
      <header className="App-section">
        <h1 style={{ fontWeight: 700 }}>Account</h1>
        <Button type={"func"} action={signOut} title="SignOut" />
      </header>
    </div>
  );
}
