import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useNavigate,
} from "react-router-dom";
import ErrorPage from "./components/_common/ErrorPage";

import logo from "./logo.svg";
import "./App.css";
import Menu from "./components/Menu/Menu";
import Footer from "./components/Footer/Footer";

import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Why from "./pages/Why";

import SignIn from "./pages/Auth/SignIn";

import Account from "./pages/Account";
import Dashboard from "./pages/Dashboard";

export const AuthContext = React.createContext();

//

function App() {
  React.useEffect(() => {
    document.title = "Tangible - Real Social";
  }, []);
  //AUTH CONTEXT
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userToken: action.token,
            user: action.user,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            user: action.user,
            //user: action.user
            //userFull:
            //cachedAt: new Date()
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            user: null,
            //userFull:
            //userFull:
            //cachedAt: new Date()
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );

  const authContext = React.useMemo(
    () => ({
      signIn: async (email, password) => {
        // In a production app, we need to send some data (usually username, password) to server and get a token
        // We will also need to handle errors if sign in failed
        // After getting token, we need to persist the token using `SecureStore`
        // In the example, we'll use a dummy token
        dispatch({
          type: "SIGN_IN",
          token: "fakeToken", //data.sessionToken, //data.sessionToken.sessionToken,
          user: "fakrUser", //data.user,
          //session: data.sessionToken,
        });

        //return { status: true, message: "Authenticated" };
        return true;

        /**/
        try {
          //let url = 'http://localhost:3006/auth/signin'
          //let url = 'http://192.168.0.158:3006/auth/signin' /
          //let url = "http://192.168.1.64:3006/auth/signin";
          let url = "http://192.168.0.158:3031/auth/login";
          //let url = 'https://tickets.vizcache.com/auth/signin'

          //BUILD POST REQUEST.
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
          myHeaders.append("Accept", "application/json");
          //myHeaders.append("Content-Type", "multi");

          //Not implemented by React Native
          /*
            var urlencoded = new URLSearchParams();
            urlencoded.append("email", data.email);
            urlencoded.append("password", data.password);
  
            let formData = new FormData();
            formData.append("email", data.email);
            formData.append("password", data.password);
            */

          var details = {
            email: email,
            password: password,
            //grant_type: "password",
          };

          var formBody = [];
          for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
          }
          formBody = formBody.join("&");

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formBody, //formData, //urlencoded,
            redirect: "follow",
          };

          //console.log("urlencoded", urlencoded);
          /*
          await fetch(url, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              //isAuth = data.isAuth;
              //token = data.sessionToken.sessionToken;
              //user = data.user
              if (data.statusCode === 401) {
                console.log(data);
                alert(JSON.stringify(data));
                dispatch({ type: "SIGN_OUT" });
              } else {
                console.log(data);
                //secureSave('userToken', data.sessionToken);
                //secureSave('userData', JSON.stringify(data.user));

                //Save authTOKEN to Document/_init/init.txt

                dispatch({
                  type: "SIGN_IN",
                  token: data.sessionToken, //data.sessionToken.sessionToken,
                  user: data.user,
                  //session: data.sessionToken,
                });
              }
            });
            */
        } catch (error) {
          console.log("sign in error:", error);
        }

        //
      },
      signIn2: (token, user) => {
        dispatch({
          type: "SIGN_IN",
          token: token, //data.sessionToken.sessionToken,
          user: user,
          //session: data.sessionToken,
        });
      },
      signOut: () => {
        //props.navigation.navigate('signIn');
        dispatch({ type: "SIGN_OUT" });
      },
      signUp: async (data) => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // After getting token, we need to persist the token using `SecureStore`
        // In the example, we'll use a dummy token

        dispatch({ type: "SIGN_IN", token: "dummy-auth-token" });
      },
      //resetPassword();
      getUserData: async () => {
        return state.user;
      },
      getIsSignout: () => {
        return state.isSignout;
      },
    }),
    [state.user]
  );

  const ProtectedRoute = ({ state, children }) => {
    //https://www.robinwieruch.de/react-router-private-routes/
    if (state.isSignout) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      //element: <Home />,
      element: <SignIn />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/why",
      element: <Why />,
    },
    {
      path: "/login",
      element: <SignIn />,
    },
    //Protected Routes
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute state={state}>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      //path: "contacts/:contactId",
      path: "/account",
      element: (
        <ProtectedRoute state={state}>
          <Account />
        </ProtectedRoute>
      ),
    },
  ]);

  //https://www.robinwieruch.de/react-router-private-routes/
  return (
    <AuthContext.Provider value={authContext}>
      <RouterProvider router={router} />
    </AuthContext.Provider>
  );
}

export default App;

/* <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
*/
