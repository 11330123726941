import React, { useContext, useEffect, useState } from "react";
import "./Menu.css";

import { Link } from "react-router-dom";

import { UserCircle } from "../_common/Icons";

import { AuthContext } from "../../App";

import Button from "../Button";

export default function Menu() {
  const { signOut, getIsSignout } = useContext(AuthContext);

  return (
    <div className="menu-container-transparent">
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "left",
          marginLeft: 10,
        }}
      >
        <Link to="/">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="./Tangible_Logo.png" className="App-logo2" alt="logo" />
            <h1 style={{ marginLeft: 10, fontWeight: 300 }}>Tangible</h1>
          </div>
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "right",
          marginRight: 10,
        }}
      >
        <div>
          <Button type={"link"} to="/login" title={"Login"} />
        </div>
      </div>
    </div>
  );
}
